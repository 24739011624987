import SelectInput from "components/selectInput/index";
import useInputChange from "hooks/useInputChange";
import { useState } from "react";
import { useMutation } from "react-query";
import { updateKyc } from "services/user.service";
import toast from "react-hot-toast";
import ErrorBox from "components/errorBox/index";
import Button from "components/button/index";
import FileUpload from "components/fileUpload/index";

export default function KycForm({ onSuccess, data }) {
  const { state, onChangeByNameValue } = useInputChange({
    documentType: data?.kyc?.documentType || "",
    document: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoading, mutate } = useMutation(
    async (input) => await updateKyc(input),
    {
      onSuccess(data) {
        toast.success(data?.message);
        onSuccess();
      },
      onError(error) {
        setErrorMessage(error?.response?.data?.message);
      },
    }
  );

  const documentTypes = [
    {
      label: "National Identification Number (NIN)",
      value: "NIN",
    },
    {
      label: "International passport",
      value: "International passport",
    },
    {
      label: "voters card",
      value: "voters card",
    },
  ];

  const handleUplaod = (e) => {
    e.preventDefault();

    mutate(state);
  };

  return (
    <form className="my__profile__modal111" onSubmit={handleUplaod}>
      <h3 className="title">
        Complete KYC <span>({data?.kycVerificationStatus})</span>
      </h3>
      <p className="info">
        This verification is required in other to be awarded any prize for the
        contest
      </p>

      <div className="db__gender">
        <SelectInput
          label="Document Type"
          name="documentType"
          value={state.documentType}
          options={documentTypes}
          handleChange={(name, option) => onChangeByNameValue(name, option)}
          placeHolder="Select"
          isRequired={true}
          disabled={data?.isKycVerified}
        />
      </div>
      <br />
      {data?.kycVerificationStatus !== "Verified" && (
        <div className="db__gender">
          <FileUpload
            name="document"
            value={state?.document}
            onChange={onChangeByNameValue}
            fallbackSrc={null}
            reuired={true}
            label={data?.kyc ? "Change File" : "Upload File"}
          />
          <p className="file-name">{data?.kyc?.document?.name}</p>
        </div>
      )}

      {errorMessage && <ErrorBox errorMessage={errorMessage} />}
      {data?.kycVerificationStatus !== "Verified" && (
        <div className="changes">
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </div>
      )}
    </form>
  );
}
