export default function Button({ children, isLoading, ...rest }) {
  return (
    <button
      className=" transition-all  duration-1000  ease-in-out min-h-[43px] rounded-sm py-3 px-5 font-bold font-heading leading-5 flex justify-center items-center bg-gradient-to-r from-[#D3A837] via-[#FFDB7E] to-[#F2CB66] hover:to-[#D3A837] hover:via-[#D3A837] shadow-2xl shadow-[#D3A83740] "
      {...rest}
    >
      {isLoading ? <span>Loading...</span> : children}
    </button>
  );
}
