import Button from "components/button/index";
import ErrorBox from "components/errorBox/index";
import TextInput from "components/inputs/TextInput";
import useInputChange from "hooks/useInputChange";
import { useMutation, useQueryClient } from "react-query";
import { updateUserProfile } from "services/user.service";
import toast from "react-hot-toast";
import { useState } from "react";
import HeadshotUpload from "components/headshotUpload/index";
import { DefaultUser } from "assets/index";
import { UserType } from "constants/enums";
import SelectInput from "components/selectInput/index";
import PhoneInputField from "components/phoneInputField/index";
import { parsePhoneNumber } from "react-phone-number-input";
import TextArea from "components/inputs/TextArea";
import DateOfBirthInput from "components/dateOfBirth/index";

export default function EditProfile({ data, onSubmit }) {
  const { state, onChange, onChangeByNameValue } = useInputChange(
    data || {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      day: "",
      month: "",
      year: "",
    }
  );

  const queryClient = useQueryClient();

  const phone = data.countryDial
    ? `+${data.countryDial}${data.phoneNumber}`
    : data.phoneNumber;

  const [errorMessage, setErrorMessage] = useState("");

  const { isLoading, mutate } = useMutation(
    async (data) => await updateUserProfile(data),
    {
      onSuccess(data) {
        toast.success(data?.message);
        queryClient.invalidateQueries();
        onSubmit();
      },
      onError(error) {
        setErrorMessage(error?.response?.data?.message);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneParse = state.phoneNumber.startsWith("+")
      ? parsePhoneNumber(state.phoneNumber)
      : parsePhoneNumber(`+${state?.countryDial}${state.phoneNumber}`);

    const dob =
      state.year && state.month && state.day
        ? `${state.month}/${state.day}/${state.year}`
        : state.dob;

    mutate({
      ...state,
      countryDial: phoneParse.countryCallingCode,
      phoneNumber: phoneParse.nationalNumber,
      dob: new Date(dob).toISOString(),
    });
  };

  return (
    <form className="w-full py-4" onSubmit={handleSubmit}>
      <h3 className="title">EDIT PROFILE</h3>
      <HeadshotUpload
        name="profilePicture"
        value={state?.profilePicture?.url}
        onChange={onChangeByNameValue}
        fallbackSrc={DefaultUser}
      />
      {data.type === UserType.Contestant && (
        <div className="flex-1">
          <TextInput
            name="stageName"
            value={state.stageName}
            onChange={onChange}
            required={true}
            label="Stage Name"
            type="text"
            placeholder="Jide"
          />
        </div>
      )}
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-[48%]">
          <TextInput
            name="firstName"
            value={state.firstName}
            onChange={onChange}
            required={true}
            label="First Name"
            type="text"
            placeholder="Jide"
          />
        </div>
        <div className="w-full lg:w-[48%]">
          <TextInput
            name="lastName"
            value={state.lastName}
            onChange={onChange}
            required={true}
            label="Last Name"
            type="text"
            placeholder="Jones"
          />
        </div>
      </div>

      <div className="flex">
        <TextInput
          name="email"
          value={state.email}
          onChange={onChange}
          required={true}
          label="Email Address"
          type="email"
          placeholder="jidejones@gmail.com"
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-[48%]">
          <PhoneInputField
            value={phone}
            onChange={(val) => onChangeByNameValue("phoneNumber", val)}
            label="Phone Number"
            required
          />
          {/* <TextInput
            name="phoneNumber"
            value={state.phoneNumber}
            onChange={onChange}
            required={true}
            label="Mobile Number"
            type="number"
            placeholder="08171878521"
          /> */}
        </div>
        <div className="w-full lg:w-[48%]">
          <TextInput
            name="location"
            value={state.location}
            onChange={onChange}
            required={true}
            label="Location"
            type="text"
            placeholder=""
          />
        </div>
      </div>
      {data.type === UserType.Contestant && (
        <div className="flex flex-col  gap-4">
          <div className="w-full lg:w-[48%]">
            <DateOfBirthInput
              name="dob"
              value={state.dob}
              onChange={onChangeByNameValue}
              required={true}
              type="date"
              label="Date of Birth"
              placeholder="Date of Birth"
            />
          </div>
          <div className="w-full ">
            <SelectInput
              label="Gender"
              name="gender"
              value={state.gender}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              handleChange={(name, option) => onChangeByNameValue(name, option)}
              contexts={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              placeHolder="Select"
              isRequired={true}
            />
          </div>
        </div>
      )}
      {data.type === UserType.Voter && (
        <div className="flex-1">
          <SelectInput
            label="Gender"
            name="gender"
            value={state.gender}
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            handleChange={(name, option) => onChangeByNameValue(name, option)}
            contexts={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            placeHolder="Select"
            isRequired={true}
          />
        </div>
      )}
      {data.type === UserType.Contestant && (
        <div className="w-full min-w-full mt-3  ">
          <TextArea
            name="about"
            value={state.about}
            onChange={onChange}
            required={true}
            type="text"
            label="About Me"
            placeholder="I started singing at age 6..."
            rows="5"
            cols="20"
          />
        </div>
      )}

      {errorMessage && <ErrorBox errorMessage={errorMessage} />}
      <div className="changes mb-4">
        <Button type="submit" isLoading={isLoading}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}
