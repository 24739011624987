//contestants route definitions
export const CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE =
  "/content-management/contestant";

export const CONTESTANT_VIEW_ALL_CONTEST_ROUTE = "contests";
export const CONTESTANT_VIEW_ALL_CONTEST_DETAILS = "contests/:id/details";
export const CONTESTANT_VIEW_MY_CONTEST_PHASES = "contests/:id/phases";
export const CONTESTANT_VIEW_MY_CONTEST_PHASES_DETAILS = "submission/:id";
export const CONTESTANT_VIEW_MY_PHASE2_DETAILS = "view-phase2-details";
export const CONTESTANT_VIEW_MY_CONTEST_APPLICATION_FORM =
  "contests/:id/application";
export const CONTESTANT_VIEW_MY_CONTEST_PHASE1_TABLE = "phases/:id";
export const CONTESTANT_VIEW_MY_CONTEST_PHASE2_TABLE = "phases2-table";
export const CONTESTANT_VIEW_ALL_CONTEST_PRIZE_ROUTE = "prizes";
export const CONTESTANT_VIEW_PHASE_RESULTS_ROUTE = "results";
export const CONTESTANT_VIEW_CONTEST_RESULTS_ROUTE = "results/contests/:id";
export const CONTESTANT_VIEW_ALL_CONTEST_VIDEO = "videos";
export const CONTESTANT_VIEW_ALL_STORIES = "stories";
export const CONTESTANT_VIEW_ALL_STORIES_PHASE_DETAILS = "stories/:id/details";
export const CONTESTANT_DASHBOARD_ROUTE = "dashboard";
export const CONTESTANT_VIDEOS_IN_CONTEST_ROUTE = "contests/:id/video-phase";
export const NOTIFICATION_INDEX_ROUTE = "notifications";
export const PROFILE_INDEX_ROUTE = "profile";
export const VOTER_PROFILE_INDEX_ROUTE = "voter-profile";
export const TRANSACTIONS_ROUTE = "transactions";
