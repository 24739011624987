import React from "react";
import "./primary-card.scss";
const PrimaryCard = ({ children }) => {
  return (
    <div className="p-4  lg:p-10">
      <div className="border w-full shadow-lg p-4 border-[#6666661F] py-8  gap-4  lg:px-[34px] bg-[#FFFFFF] rounded-lg min-h-[106px] shadow-[#0000000D]">
        {children}
      </div>
    </div>
  );
};

export default PrimaryCard;
