import RoundUserAvatar from "components/round-user-avatar/RoundUserAvatar";
import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./avatar-button.scss";
import AvatarDropdown from "components/avatar-dropdown/AvatarDropdown";

const AvatarButton = ({
  imgSrc,
  showAvatarDropdown,
  setShowAvatarDropdown,
  data,
  userProfileUrl,
}) => {
  return (
    <div className="avatar-button group ">
      <button
        type="button"
        className="flex items-center"
        onClick={() =>
          setShowAvatarDropdown((showAvatarDropdown) => !showAvatarDropdown)
        }
      >
        <RoundUserAvatar imgSrc={imgSrc} />
        <span
          className={`avatar-button__icon ${
            showAvatarDropdown ? "icon--rotated" : "icon--unset-rotate"
          }`}
        >
          <MdOutlineKeyboardArrowDown color="#565656" />
        </span>
      </button>

      <div className=" hidden group-hover:block ">
        <AvatarDropdown
          imgSrc={data?.data?.profilePicture?.url}
          showDropdown={true}
          email={data?.data?.email}
          name={`${data?.data?.firstName} ${data?.data?.lastName}`}
          userProfileUrl={userProfileUrl}
        />
      </div>
    </div>
  );
};

export default AvatarButton;
