import React from "react";
import propTypes from "prop-types";
import "./textArea.scss";

const TextArea = ({
  label,
  className = "textarea",
  placeholder,
  required,
  ...rest
}) => {
  return (
    <div className="area-container">
      {label && (
        <p className="label">
          {label} {required && <i className="required-text">*</i>}
        </p>
      )}

      <textarea
        placeholder={placeholder}
        className={className}
        required={required}
        {...rest}
      ></textarea>
    </div>
  );
};
TextArea.propTypes = {
  rows: propTypes.string,
  cols: propTypes.string,
  label: propTypes.string,
  className: propTypes.string,
  placeholder: propTypes.string,
};

export default TextArea;
