import { DefaultUser } from "assets/index";
import propTypes from "prop-types";
import { useState, useEffect } from "react";
const Image = ({ src = "", alt, fallbackSrc = DefaultUser, clickHandler }) => {
  const [errored, setErrored] = useState(0);
  const [imgSrc, setImgSrc] = useState(src || fallbackSrc);

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);

  const handleClick = () => {
    if (clickHandler) clickHandler();
  };
  const handleOnError = () => {
    if (errored < 2) {
      setImgSrc(fallbackSrc);
      setErrored(errored + 1);
    }
  };

  return (
    <img
      src={imgSrc}
      onError={handleOnError}
      alt={alt}
      loading="lazy"
      onClick={handleClick}
    />
  );
};

export default Image;
Image.propTypes = {
  src: propTypes.string,
  fallbackSrc: propTypes.any,
  alt: propTypes.string,
  clickHandler: propTypes.func,
};
