import Modal from "components/modal/Modal";
import { LANDING_ROUTE } from "constants/urls/Landing";
import React, { useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Button from "../Button";
import "./logout.scss";
import { clearAccessToken, clearRefreshToken } from "utils/storage";

const Logout = () => {
  const [confirmationModal, setConfirmationModal] = useState(false);

  const confirmationModalIsopen = () => {
    setConfirmationModal((prev) => !prev);
  };
  const confirmationModalIsclose = () => {
    setConfirmationModal();
  };

  const navigate = useNavigate();
  const landingPath = () => {
    toast.success("You have been successfully logged out");

    clearAccessToken();
    clearRefreshToken();
    setConfirmationModal(false);
    navigate(`${LANDING_ROUTE}`);
  };

  return (
    <div className="logout-user--button__holder">
      {confirmationModal && (
        <Modal
          showModal={confirmationModalIsopen}
          showModalHandler={confirmationModalIsclose}
        >
          <div className="confirmation-modal-container">
            <p>Are You Sure You Want To Log out?</p>
            <p>You'll be logged out of this account.</p>
            <div className="btn-container">
              <Button
                btnText="CANCEL"
                clickHandler={confirmationModalIsclose}
                className="confirmation-cancle-btn"
              />
              <Button btnText="LOG OUT" clickHandler={landingPath} />
            </div>
          </div>
        </Modal>
      )}
      <button
        className="button"
        type="button"
        onClick={confirmationModalIsopen}
      >
        <IoIosLogOut />
        <span className="logout-text">log out</span>
      </button>
    </div>
  );
};

export default Logout;
