import { handleGetRequest, handlePostRequest } from "./httpClient";

export const voteSubmission = async (phaseId, data) => {
  return await handlePostRequest(`/phase-submissions/${phaseId}/vote`, data);
};
export const getPhaseSubmissions = async (phaseId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(
    `/phase-submissions/phases/${phaseId}${queryString}`
  );
};
export const getOneSubmissions = async (submissionId) => {
  return await handleGetRequest(`/phase-submissions/${submissionId}`);
};
export const getOneSubmissionByUrlId = async (urlId) => {
  return await handleGetRequest(`/phase-submissions/details/${urlId}`);
};
export const getPhaseVoteCount = async (phaseId) => {
  return await handleGetRequest(`/phases/${phaseId}/vote-count`);
};

const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }
  if (params.status) {
    query = query.concat(`&status=${params.status}`);
  }

  return query;
};
