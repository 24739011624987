import React, { lazy } from "react";
import * as ContestantUrl from "constants/urls/ContentManagement";
import { Routes, Route } from "react-router-dom";
import AllContests from "pages/content-management/contestant/contests/components/AllContests";
import MyContests from "pages/content-management/contestant/contests/components/MyContests";

const ViewDashboard = lazy(() =>
  import("pages/content-management/contestant/dashboard/Dashboard")
);

const ViewAllContests = lazy(() =>
  import("pages/content-management/contestant/contests/Contests")
);
const ViewContestDetails = lazy(() =>
  import(
    "pages/content-management/contestant/contests/view-contest-details/ViewContestDetails"
  )
);

const MyContestsPhases = lazy(() =>
  import(
    "pages/content-management/contestant/contests/mycontests-phases/MyContestsPhases"
  )
);
const MyContestPhasesTable = lazy(() =>
  import(
    "pages/content-management/contestant/contests/phases-table/MyContestPhasesTable"
  )
);

const MyContestPhase2Table = lazy(() =>
  import(
    "pages/content-management/contestant/contests/phases-table/MyContestPhse2Table"
  )
);

const PhaseDetails = lazy(() =>
  import(
    "pages/content-management/contestant/contests/view-phase-details/PhaseDetails"
  )
);

const ApplicationForm = lazy(() =>
  import(
    "pages/content-management/contestant/contests/application-form/ApplicationForm"
  )
);

const ViewAllContestPrizes = lazy(() =>
  import("pages/content-management/contestant/prizes/Prizes")
);
const ViewAllPhaseResult = lazy(() =>
  import("pages/content-management/contestant/results/Results")
);
const Results = lazy(() =>
  import("pages/content-management/contestant/results/resultContest")
);
const ViewContestVideo = lazy(() =>
  import("pages/content-management/contestant/videos/Videos")
);

const ViewAllStories = lazy(() =>
  import("pages/content-management/contestant/stories/Stories")
);
const StoriesPhaseDetailsView = lazy(() =>
  import(
    "pages/content-management/contestant/stories/phase-details/StoriesPhaseDetailsView"
  )
);

const ViewVideosInContest = lazy(() =>
  import("pages/content-management/contestant/videos/video-phase/VideoPhase")
);

const Profile = lazy(() => import("pages/profile/Profile"));

const Notification = lazy(() => import("pages/notification/Notification"));
const NotificationDetails = lazy(() =>
  import("pages/notification/NotificationDetails/index")
);

const Transactions = lazy(() =>
  import("pages/content-management/contestant/transactions")
);
const ContestantRoutes = () => {
  return (
    <Routes>
      <Route
        path={ContestantUrl.NOTIFICATION_INDEX_ROUTE}
        element={<Notification />}
      />
      <Route
        path={`${ContestantUrl.NOTIFICATION_INDEX_ROUTE}/:id`}
        element={<NotificationDetails />}
      />
      <Route path={ContestantUrl.PROFILE_INDEX_ROUTE} element={<Profile />} />
      <Route
        path={ContestantUrl.CONTESTANT_DASHBOARD_ROUTE}
        element={<ViewDashboard />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_CONTEST_ROUTE}
        element={<ViewAllContests />}
      >
        <Route index element={<AllContests />} />
        <Route path={`all`} element={<AllContests />} />
        <Route path={`my-contests`} element={<MyContests />} />
      </Route>
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_CONTEST_DETAILS}
        element={<ViewContestDetails />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_MY_CONTEST_PHASES_DETAILS}
        element={<PhaseDetails />}
      />{" "}
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_MY_CONTEST_APPLICATION_FORM}
        element={<ApplicationForm />}
      />{" "}
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_MY_CONTEST_PHASE1_TABLE}
        element={<MyContestPhasesTable />}
      />{" "}
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_MY_CONTEST_PHASE2_TABLE}
        element={<MyContestPhase2Table />}
      />{" "}
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_MY_CONTEST_PHASES}
        element={<MyContestsPhases />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_CONTEST_PRIZE_ROUTE}
        element={<ViewAllContestPrizes />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_PHASE_RESULTS_ROUTE}
        element={<Results />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_CONTEST_RESULTS_ROUTE}
        element={<ViewAllPhaseResult />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_CONTEST_VIDEO}
        element={<ViewContestVideo />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIDEOS_IN_CONTEST_ROUTE}
        element={<ViewVideosInContest />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_STORIES}
        element={<ViewAllStories />}
      />
      <Route
        path={ContestantUrl.CONTESTANT_VIEW_ALL_STORIES_PHASE_DETAILS}
        element={<StoriesPhaseDetailsView />}
      />
      <Route
        path={ContestantUrl.TRANSACTIONS_ROUTE}
        element={<Transactions />}
      />
    </Routes>
  );
};

export default ContestantRoutes;
