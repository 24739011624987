import React from "react";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { MdPlayArrow } from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import "./inlineNavigation.scss";

const InlineNavigation = ({ previousPage, currentPage = "" }) => {
  const navigate = useNavigate();
  return (
    <nav className="page-inline-navigations">
      <ul>
        <li>
          <button
            type="button"
            className="routeBack__toPrevPage"
            onClick={() => navigate(-1)}
          >
            <HiOutlineArrowNarrowLeft />
            <span>{previousPage}</span>
            {currentPage !== "" && <MdPlayArrow />}
          </button>
        </li>
        <li>
          <span>{currentPage}</span>
        </li>
      </ul>
    </nav>
  );
};

InlineNavigation.propTypes = {
  previousPage: propTypes.string,
  currentPage: propTypes.string,
};

export default InlineNavigation;
