import DashBoardHeader from "components/headers/dashboard-header/DashBoardHeader";
import React, { useState } from "react";
import ViewAllContestantsCard from "./ViewAllContestantCard";
import Modal from "components/modal/Modal";
import Button from "components/button/Button";
import toast from "react-hot-toast";
import headestSvgImage from "assets/svgs/headset-avatar.svg";
import InlineNavigation from "components/inline-navigation/InlineNavigation";
import brandlogo from "assets/images/brand-logo.png";
import "./view-all-contestant.scss";
import { useParams, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Jumbotron from "components/jumbotron/index";
import Image from "components/image/index";
import { useMutation, useQueryClient } from "react-query";
import { voteSubmission } from "services/phase.service";
import useCurrentUser from "hooks/useCurrentUser";
// import useContestsCurrentSubmissions from "hooks/useContestsCurrentSubmissions";
import PrimaryCard from "components/primarycard/PrimaryCard";
import usePhaseSubmissions from "hooks/usePhaseSubmissions";

const ViewAllContestant = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [contestant, setContestant] = useState(null);
  const params = useParams();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const { data, isLoading } = usePhaseSubmissions(params?.id);

  const queryClient = useQueryClient();

  const { isLoading: voting, mutate: voteMutation } = useMutation(
    async (data) => await voteSubmission(contestant?._id, data),
    {
      onSuccess(data) {
        toast.success(data.message);
        setOpenModal1(false);
        setOpenModal(false);
        queryClient.invalidateQueries();
      },
      onError(error) {
        toast.error(error.response.data.message);
      },
    }
  );

  const handleUpVote = () => {
    const payload = {
      isQualified: true,
    };
    voteMutation(payload);
  };

  const modalIsOpen = () => {
    setOpenModal((prev) => !prev);
  };
  const modalIsClose = () => {
    setOpenModal();
  };

  const modalIsOpen1 = () => {
    setOpenModal1((prev) => !prev);
  };
  const modalIsClose1 = () => {
    setOpenModal1();
  };

  const handleView = (contestant) => {
    setContestant(contestant);
    modalIsOpen();
  };

  return (
    <div className="main__wrap">
      {openModal && (
        <Modal
          showModal={modalIsOpen}
          showModalHandler={modalIsClose}
          title="Vote Contestant"
        >
          <div className="video-modal">
            <div className="avatar-name">
              <div className="avatar-container">
                <Image
                  src={contestant?.user?.profilePicture?.url}
                  alt="avatar"
                />
              </div>
              <div className="name-carrier">
                <span className="jone__doe">
                  {contestant?.user?.firstName} {contestant?.user?.lastName}
                </span>
                <span className="musical">{contestant.user?.stageName}</span>
              </div>
            </div>
            <p className="cover">
              {contestant?.user?.about}
              {/* {truncateWords(
                contestant?.videoUpload?.name ||
                  contestant?.videoUpload?.name ||
                  "",
                15
              )} */}
            </p>
            <div className="video-container">
              {contestant?.videoUrl ? (
                <a href={contestant?.videoUrl} target="_blank" rel="noreferrer">
                  Watch Video
                </a>
              ) : (
                <video
                  width="310"
                  height="170"
                  controls
                  className="max-h-[300px]"
                >
                  <source src={contestant?.videoUpload?.url} type="video/mp4" />
                </video>
              )}
            </div>
            {contestant?.votedBy?.includes(currentUser?.id) ? (
              <p className="voted-note">
                Thank you!, Your vote has been recorded!
              </p>
            ) : (
              <div className="btn-container">
                <Button btnText="VOTE" clickHandler={modalIsOpen1} />
              </div>
            )}
          </div>
        </Modal>
      )}

      {openModal1 && (
        <Modal
          showModal={modalIsOpen1}
          showModalHandler={modalIsClose1}
          title="Confirm Vote"
        >
          <div className="confirmation-modal p-4">
            <p className="font-body text-sm text-center text-text">
              Are you sure you want to vote for this contestant ?
            </p>
            <div className="confirm-btn">
              <Button btnText="NO" clickHandler={modalIsClose1} />
              <Button
                btnText={voting ? "PROCESSING..." : "YES"}
                clickHandler={handleUpVote}
              />
            </div>
          </div>
        </Modal>
      )}
      <DashBoardHeader
        headerTitle="Voters"
        brandLogo={brandlogo}
        locationLink={false}
      />

      <PrimaryCard>
        <div className="voter__contestant__bg mt-[4rem] lg:mt-1">
          <InlineNavigation
            previousPage={location?.state?.contest}
            currentPage="Contestants"
          />
          <div className="voter-contestants">
            {isLoading ? (
              <Skeleton height={300} width="48%" />
            ) : (
              <>
                {data?.data?.total < 1 ? (
                  <Jumbotron message="No Contestants" />
                ) : (
                  <>
                    {data?.data?.data?.map((contestant) => (
                      <div key={contestant._id} className="voter-contestant">
                        <ViewAllContestantsCard
                          className="card__wrapper"
                          video={contestant?.videoUpload}
                          videoUrl={contestant?.videoUrl}
                          paragraph={
                            <div className="video__discription">
                              <p className="paragraph">
                                @{contestant?.user?.stageName}
                              </p>
                              <p className="paragraph">
                                {contestant?.talentCategory}
                              </p>
                              <p className="paragraph">
                                {contestant?.user?.location}
                              </p>
                            </div>
                          }
                          title={`${contestant?.user?.firstName} ${contestant?.user?.lastName}`}
                          handleClick={() => handleView(contestant)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>

          <span className="headset-icon">
            <img src={headestSvgImage} alt="headset icon" />
          </span>
        </div>
      </PrimaryCard>
    </div>
  );
};

export default ViewAllContestant;
