import { useState } from "react";

export default function useInputChange(val) {
  const [state, setState] = useState(val);

  const onChange = (e) => {
    // @ts-ignore
    const value = e.target.checked || e.target.value;

    setState((curr) => ({
      ...curr,
      [e.target.name]: value,
    }));
  };

  const onChangeByNameValue = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return { state, onChange, onChangeByNameValue, setState };
}
