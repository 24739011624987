import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const applyTocontest = async (contestId, input) => {
  return await handlePostRequest(`/contests/${contestId}/contestants`, input);
};

export const getContests = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/contests${queryString}`);
};

export const getOngoingConsent = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/general/ongoing-consent${queryString}`);
};

export const getContestContestants = async (contestId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(
    `/contests/${contestId}/contestants${queryString}`
  );
};

export const getPublicContestContestants = async (contestId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(
    `/general/contestants/contests/${contestId}${queryString}`
  );
};

export const getContestsWithVideos = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/contests/contest-with-videos${queryString}`);
};

export const getUserContest = async (userId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(
    `/contestant/contests/users/${userId}/${queryString}`
  );
};

export const getOneContest = async (id) => {
  return await handleGetRequest(`/contests/${id}`);
};

export const getOnePublicContest = async (id) => {
  return await handleGetRequest(`/general/contests/${id}`);
};

export const deleteContest = async (id) => {
  return await handleDeleteRequest(`/contests/${id}`);
};

export const editContest = async (id, input) => {
  return await handlePuttRequest(`/contests/${id}`, input);
};

export const getContestStats = async (id) => {
  return await handleGetRequest(`/contests/${id}/stats`);
};

export const createContestPhase = async (contestId, input) => {
  return await handlePostRequest(`/contests/${contestId}/phases`, input);
};

export const getContestPhases = async (contestId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/contests/${contestId}/phases${queryString}`);
};

export const getOnePhase = async (id) => {
  return await handleGetRequest(`/phases/${id}`);
};

export const deleteContestPhase = async (phaseId) => {
  return await handleDeleteRequest(`/phases/${phaseId}`);
};

export const editContestPhase = async (phaseId, input) => {
  return await handlePuttRequest(`/phases/${phaseId}`, input);
};

export const getContestJudges = async (id) => {
  return await handleGetRequest(`/contests/${id}/judges`);
};

export const getContestCurrentSubmissions = async (contestId) => {
  return await handleGetRequest(`/contests/${contestId}/current-submissions`);
};

export const getAvailableContests = async () => {
  return await handleGetRequest(`/contests/available-contests`);
};

const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }
  if (params.isPublicVotable) {
    query = query.concat(`&isPublicVotable=${params.isPublicVotable}`);
  }

  return query;
};
