import React from "react";
import "./notification-button.scss";
import { useMutation } from "react-query";
import { updateNotificationView } from "services/user.service";
import useNewNotificationCount from "hooks/useNotificationCount";
import { bell } from "assets/index";

const NotificationButton = () => {
  const { data: newNotification } = useNewNotificationCount();

  const { mutate: updateNotificationViewMutate } = useMutation(
    async () => await updateNotificationView()
  );

  const handleNotification = () => {
    updateNotificationViewMutate();
  };

  return (
    <div className="notification-button__block relative">
      <button
        onClick={handleNotification}
        className="notification-button"
        type="button"
      >
        <img src={bell} className="bell" alt="bell" />

        {newNotification && newNotification.data > 0 && (
          <div className="w-[20px] h-[20px] rounded-full bg-red-500 flex justify-center items-center absolute -right-1 -top-2">
            <span className="text-xs text-background">
              {newNotification.data}
            </span>
          </div>
        )}
      </button>
    </div>
  );
};

export default NotificationButton;
