import propTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import "./date.scss";
import SelectInput from "components/selectInput/index";
import { MONTHS } from "utils/helper";
import useInputChange from "hooks/useInputChange";

const currentDate = new Date();
const CURRENT_YEAR = currentDate.getFullYear();
const YEAR_LIMIT = CURRENT_YEAR - 100;

const DateOfBirthInput = ({ label, value, onChange, required }) => {
  const { state, onChangeByNameValue, setState } = useInputChange({
    day: "",
    month: "",
    year: "",
  });
  const [days, setDays] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    generateDays();
    generateMonths();

    if (value) {
      const date = new Date(value);
      setState({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
  }, [value, setState]);

  const generateDays = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({
        label: `${i}`,
        value: i,
      });
    }

    setDays(days);
  };

  const generateMonths = () => {
    const months = [];
    for (let i = 0; i < MONTHS.length; i++) {
      months.push({
        label: MONTHS[i],
        value: i + 1,
      });
    }

    setMonths(months);
  };

  const generateYears = useMemo(() => {
    const years = [];

    for (let i = CURRENT_YEAR; i >= YEAR_LIMIT; i--) {
      years.push(i);
    }

    return years;
  }, []);

  const handleChange = (name, value) => {
    onChangeByNameValue(name, value);

    onChange(name, value);
  };

  return (
    <div className="textInput-container flex flex-col gap-2 flex-1 ">
      {label && (
        <p className=" text-sm">
          {label} {required && <i className="required-text">*</i>}
        </p>
      )}
      <div className="flex flex-col md:flex-row  md:items-center w-full">
        <div className="flex-1">
          <SelectInput
            name="day"
            options={days}
            value={state.day}
            handleChange={(name, option) => handleChange(name, option)}
            placeHolder="Day"
            search={false}
            isRequired={required}
          />
        </div>

        <div className="flex-1">
          <SelectInput
            name="month"
            value={state.month}
            options={months}
            handleChange={(name, option) => handleChange(name, option)}
            placeHolder="Month"
            search={false}
            isRequired={required}
          />
        </div>
        <div className="flex-1 ">
          <SelectInput
            name="year"
            value={state.year}
            options={generateYears.map((year) => ({
              label: year.toString(),
              value: year,
            }))}
            handleChange={(name, option) => handleChange(name, option)}
            placeHolder="Year"
            search={false}
            isRequired={required}
          />
        </div>
      </div>
    </div>
  );
};
DateOfBirthInput.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
};
export default DateOfBirthInput;
