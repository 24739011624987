import propTypes from "prop-types";
import "./textInput.scss";

const TextInput = ({
  label,
  placeholder,
  className = "text-input",
  type = "text",
  required,
  ...rest
}) => {
  return (
    <div className="textInput-container">
      {label && (
        <label className="label">
          {label}
          {required && <i className="required-text">*</i>}{" "}
        </label>
      )}

      <input
        type={type}
        placeholder={placeholder}
        className={className}
        required={required}
        {...rest}
      />
    </div>
  );
};
TextInput.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
};
export default TextInput;
