import { handleDeleteRequest, handlePostRequest } from "./httpClient";

export const uploadFile = async (formData) => {
  return await handlePostRequest("/files/single", formData);
};
export const uploadVideo = async (formData) => {
  return await handlePostRequest("/files/video", formData);
};
export const deleteFile = async (fileId) => {
  return await handleDeleteRequest(`/files/${fileId}`);
};
