import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import PrimaryCard from "components/primarycard/PrimaryCard";
import Button from "components/button";
import Modal from "components/modal/Modal";
import brandlogo from "assets/images/brand-logo.png";
import toast from "react-hot-toast";
import InlineNavigation from "components/inline-navigation/InlineNavigation";
import DashBoardHeader from "components/headers/dashboard-header/DashBoardHeader";
import "./voter-profile.scss";
import useInputChange from "hooks/useInputChange";
import useCurrentUser from "hooks/useCurrentUser";
import useUserDetails from "hooks/useUserDetails";
import { changePassword } from "services/user.service";
import { useMutation } from "react-query";
import Image from "components/image/index";
import { DefaultUser } from "assets/index";
import EditProfile from "./components/EditProfile";
import PasswordInput from "components/inputs/PasswordInput";
import { formatDate } from "utils/helper";
import { UserType } from "constants/enums";
import ErrorBox from "components/errorBox/index";
import KycForm from "./components/KycForm";
// import { parsePhoneNumber } from "react-phone-number-input";

function VoterProfile() {
  const [openModal, setOpenModal] = useState(false);
  const [openPassModal, setOpenPassModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showKyc, setShowKyc] = useState(false);

  const currentUser = useCurrentUser();
  const { state, onChange } = useInputChange({
    oldPassword: "",
    newPassweord: "",
    confirmPassword: "",
  });

  const { isLoading, data, refetch } = useUserDetails(currentUser?.id);

  const { isLoading: changingPassword, mutate: changePasswordMutate } =
    useMutation(async (data) => await changePassword(data), {
      onSuccess(data) {
        toast.success(data?.message);
        setOpenPassModal(false);
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    });

  const modalIsOpen = () => {
    setOpenModal((prev) => !prev);
  };
  const modalIsClose = () => {
    setOpenModal();
  };

  const passModalIsOpen = () => {
    setOpenPassModal((prev) => !prev);
  };
  const passModalIsClose = () => {
    setOpenPassModal();
  };

  const handleSubmitPawwaordChange = (e) => {
    e.preventDefault();

    // validate at least 1 uppercase, 1 lowercase, 1 number and 1 specail char, 7 min lengnth
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

    if (state.newPassword.length < 8) {
      setErrorMessage("New password should not be less than 8 characters");
      return;
    }

    if (!passwordRules.test(state.newPassword)) {
      setErrorMessage(
        "New Password should contain 1 uppercase, 1 lowercase, 1 number and 1 special character"
      );
      return;
    }

    changePasswordMutate(state);
  };

  const toggleKyc = () => {
    setShowKyc(!showKyc);
  };

  const handleKycSuccess = () => {
    refetch();
  };

  return (
    <div className="wrapper1">
      <DashBoardHeader
        headerTitle="Voters"
        locationLink={false}
        brandLogo={brandlogo}
      />
      <div className="p-4 lg:pl-[6rem]">
        <InlineNavigation previousPage="Home" currentPage="Profile" />
      </div>
      {openModal && (
        <Modal showModal={modalIsOpen} showModalHandler={modalIsClose}>
          <div className="my__profile__modal">
            {/* <h3 className="title">EDIT PROFILE</h3> */}

            <EditProfile data={data?.data} onSubmit={modalIsClose} />
          </div>
        </Modal>
      )}

      {openPassModal && (
        <Modal showModal={passModalIsOpen} showModalHandler={passModalIsClose}>
          <form
            className="my__profile__modal111"
            onSubmit={handleSubmitPawwaordChange}
          >
            <h3 className="title">CHANGE PASSWORD</h3>

            <PasswordInput
              label="Old Password"
              name="oldPassword"
              value={state.oldPassword}
              onChange={onChange}
              required={true}
              type="password"
              placeholder="Old Password"
            />

            <PasswordInput
              label="New Password"
              name="newPassword"
              value={state.newPassword}
              onChange={onChange}
              required={true}
              type="password"
              placeholder="New Password"
            />

            <PasswordInput
              label="Confirm New Password"
              name="confirmPassword"
              value={state.confirmPassword}
              onChange={onChange}
              required={true}
              type="password"
              placeholder="Confirm New Password"
            />
            {errorMessage && <ErrorBox errorMessage={errorMessage} />}
            <div className="changes">
              <Button
                type="submit"
                btnText="SAVE CHANGES"
                isLoading={changingPassword}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </Modal>
      )}

      <div className="pt-4  mt-[4rem] lg:mt-0  lg:p-10">
        {isLoading ? (
          <Skeleton width="100%" height={300} />
        ) : (
          <PrimaryCard>
            <div className="row">
              <div>
                <div className="content__holder">
                  <div className="profile__image">
                    <Image
                      src={data?.data?.profilePicture?.url}
                      alt={data?.data?.firstName}
                      fallbackSrc={DefaultUser}
                    />
                  </div>
                  <div className="text">
                    <h3 className="profile__h3">
                      {" "}
                      {data?.data?.firstName} {data?.data?.lastName}
                    </h3>
                    <p className="profile__p">{data?.data?.email}</p>
                  </div>
                </div>
                <div className="edit-prof-btn">
                  <Button onClick={modalIsOpen}>
                    <span>EDIT PROFILE</span>
                  </Button>
                </div>
                <div className="dis__wrapper">
                  {data?.data?.type === UserType.Contestant && (
                    <div className="about">
                      <p className="heading">Stage Name</p>
                      <p className="discription">{data?.data?.stageName}</p>
                    </div>
                  )}
                  {data?.data?.type === UserType.Contestant && (
                    <div className="about">
                      <p className="heading">About Me</p>
                      <p className="discription">{data?.data?.about}</p>
                    </div>
                  )}

                  <div className="">
                    <p className="heading">Phone Number</p>
                    <p className="discription">
                      ({data?.data?.countryDial}){data?.data?.phoneNumber}
                    </p>
                  </div>

                  <div className="">
                    <p className="heading">Location</p>
                    <p className="discription">{data?.data?.location}</p>
                  </div>

                  {/* {data?.data?.type === UserType.Contestant && ( */}
                  <div className="">
                    <p className="heading">Gender</p>
                    <p className="discription">{data?.data?.gender}</p>
                  </div>
                  {/* )} */}

                  {data?.data?.type === UserType.Contestant && (
                    <div className="">
                      <p className="heading">Date of Birth</p>
                      <p className="discription">
                        {data?.data?.dob ? formatDate(data?.data?.dob) : ""}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {data?.data?.type === UserType.Contestant && (
                <KycForm
                  onClose={toggleKyc}
                  onSuccess={handleKycSuccess}
                  data={data?.data}
                />
              )}
            </div>
          </PrimaryCard>
        )}
      </div>
    </div>
  );
}

export default VoterProfile;
