import React, { useState } from "react";
import Button from "components/button/Button";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import { useNavigate } from "react-router-dom";
import Modal from "components/modal/Modal";
import "./myContests.scss";
import Jumbotron from "components/jumbotron/index";
import Skeleton from "react-loading-skeleton";
import Pagination from "components/pagination/Pagination";
import useContantContests from "hooks/useContestantContests";
import useCurrentUser from "hooks/useCurrentUser";
import { truncateWords } from "utils/helper";

const MyContests = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { data, isLoading, onQueryChange } = useContantContests(
    currentUser?.id
  );

  const [openModal, setOpenModal] = useState(false);
  const modalIsOpen = () => {
    setOpenModal((prev) => !prev);
  };
  const modalIsClose = () => {
    setOpenModal();
  };

  const handleCardLink = (contest) => {
    localStorage.setItem("contestTab", 1);
    navigate(
      `${ContentMgtURL.CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}/contests/${contest?._id}/phases`,
      {
        state: { contest: contest?.title },
      }
    );
  };

  const handlePaginate = (page) => {
    onQueryChange("page", page);
  };

  return (
    <div className="mycontests-container">
      {openModal && (
        <Modal showModal={modalIsOpen} showModalHandler={modalIsClose}>
          <div className="backAndCancle-modal">
            <p>Cancelling your Application</p>
            <p>
              You'll no longer be able to access the details of this contest
            </p>
            <div className="back--cancle-btn-container">
              <Button
                btnText="BACK"
                className="goback-btn"
                clickHandler={modalIsClose}
              />
            </div>
          </div>
        </Modal>
      )}

      <div className="contests-cardLink-container">
        {isLoading ? (
          <Skeleton height={300} width="48%" />
        ) : (
          <>
            {data?.data?.total < 1 ? (
              <Jumbotron message="No Contests" />
            ) : (
              <>
                {data?.data?.data?.map((application) => (
                  <div className="myContest shadow-md " key={application?._id}>
                    <div
                      onClick={() => handleCardLink(application.contest)}
                      className="myContestCard__card"
                    >
                      <div className="contestImg__holder">
                        <img
                          src={application?.contest?.coverPhoto?.url}
                          alt="img"
                        />
                      </div>
                      <p className="contest-titlex p-1">
                        {truncateWords(application?.contest?.title, 130)}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>

      <Pagination
        handlePageClick={handlePaginate}
        currentPage={data?.data.page}
        limit={data?.data?.limit}
        total={data?.data?.total}
      />
    </div>
  );
};
export default MyContests;
