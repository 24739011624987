import { handleGetRequest, handlePuttRequest } from "./httpClient";

export const getNotifications = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/notifications${queryString}`);
};

export const readNotification = async (id) => {
  return await handlePuttRequest(`/notifications/${id}/read`, {});
};
export const getOneNotification = async (id) => {
  return await handleGetRequest(`/notifications/${id}`);
};

export const getNewNotificationCount = async () => {
  return await handleGetRequest(`/notifications/new-count`);
};
const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }

  return query;
};
