import { handleGetRequest, handlePuttRequest } from "./httpClient";

export const getContestantContests = async (userId, params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(
    `/contestant/contests/users/${userId}${queryString}`
  );
};

export const cancelContestApplication = async (id) => {
  return await handlePuttRequest(`/contestant/${id}/cancel-application`);
};

export const getContestant = async (contestId) => {
  return await handleGetRequest(`/contestant/contests/${contestId}`);
};

const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }

  return query;
};
