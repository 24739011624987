import PhoneInput from "react-phone-number-input";
import "./phoneField.scss";

export default function PhoneInputField({ value, onChange, label, required }) {
  return (
    <div className="textInput-container">
      <label className="label">
        {label} {required && <i className="required-text">*</i>}
      </label>
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
        defaultCountry="NG"
        required={required}
        international
        countryCallingCodeEditable={false}
        withCountryCallingCode
        initialValueFormat="national"
        className="text-input"
        maxLength={20}
        // minLength={10}
      />
    </div>
  );
}
