import propTypes from "prop-types";
import "./passwordInput.scss";
import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const PasswordInput = ({
  label,
  placeholder,
  className = "text-input",
  type = "text",
  required,
  errorMessage,
  ...rest
}) => {
  const [isPassword, setIsPassword] = useState(true);

  const togglePassword = () => [setIsPassword(!isPassword)];
  return (
    <div className="passInput-container">
      {label && (
        <label className="label">
          {label} {required && <i className="required-text">*</i>}{" "}
        </label>
      )}

      <div className="password-wrap">
        <input
          type={isPassword ? "password" : "text"}
          placeholder={placeholder}
          className={className}
          required={required}
          {...rest}
        />
        <button type="button" className="eye-icon" onClick={togglePassword}>
          {!isPassword ? <BsEye /> : <BsEyeSlash />}
        </button>
      </div>

      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
PasswordInput.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
};
export default PasswordInput;
