import React, { useState } from "react";
// import SearchInput from "components/search-input/SearchInput";
import AvatarButton from "components/avatar-button/AvatarButton";
import NotificationButton from "components/notification-button/NotificationButton";
// import AvatarDropdown from "components/avatar-dropdown/AvatarDropdown";
import { Link } from "react-router-dom";
import useCurrentUser from "hooks/useCurrentUser";
import useUserDetails from "hooks/useUserDetails";
import { Logo, menu } from "assets/index";
import MobileDashboardSidebar from "pages/content-management/contestant/dashboard/components/MobileSidebar";
import { UserType } from "constants/enums";

const DashBoardHeader = ({ headerTitle, userProfileUrl }) => {
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const currentUser = useCurrentUser();
  const { data } = useUserDetails(currentUser?.id);

  const toggleMobileMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  return (
    <>
      <header className="flex items-center fixed top-0 right-0 left-0 z-50 lg:sticky px-4 lg:px-[50px] justify-between min-h-[72px] rounded-sm bg-[#FFFFFF] border-b border-[#00000014]">
        <div className="flex items-center gap-4">
          {data?.data?.type === UserType.Contestant && (
            <button onClick={toggleMobileMenu} className=" lg:hidden">
              <img src={menu} alt="toggle" />
            </button>
          )}

          {headerTitle === "Voters" ? (
            <img src={Logo} alt="logo" />
          ) : (
            <span className=" font-heading font-bold text-base text-text leading-5">
              {headerTitle}
            </span>
          )}

          {/* <div className="dashboard-header__search">
          <SearchInput placeholder="search..." />
        </div> */}
        </div>

        <div
          className="flex items-center gap-4 justify-end "
          // onBlur={() =>
          //   setShowAvatarDropdown((showAvatarDropdown) => !showAvatarDropdown)
          // }
        >
          {data?.data?.type === UserType.Contestant && (
            <Link to="/content-management/contestant/notifications">
              <div className="noti">
                <NotificationButton />
              </div>
            </Link>
          )}

          <div className="group">
            <AvatarButton
              imgSrc={data?.data?.profilePicture?.url}
              setShowAvatarDropdown={setShowAvatarDropdown}
              showAvatarDropdown={showAvatarDropdown}
              data={data}
              userProfileUrl={userProfileUrl}
            />
          </div>
        </div>
      </header>
      {/* MOBILE MENU */}
      <div
        className={`absolute -top-[700px]  right-0 left-0 transition-all duration-1000 ease-in-out bg-[#090702]  z-50 ${
          menuExpanded ? " top-[0px]  " : ""
        }`}
        aria-label="mobile-menu"
        aria-expanded={menuExpanded}
      >
        <MobileDashboardSidebar onClose={toggleMobileMenu} />
      </div>
    </>
  );
};

export default DashBoardHeader;
