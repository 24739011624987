import React from "react";

const OVerlay = ({ visible, children }) => {
  return (
    <div
      className={`fixed backdrop-blur-sm z-[999]  ${
        !visible
          ? "-top-[1500px] left-0 right-0 -bottom-[20px] bg-dark-500/50 "
          : " top-0 left-0 right-0 bottom-0 bg-dark/80 "
      }  w-full h-screen z-50 overflow-hidden   flex flex-col pt-8 items-center justify-center `}
    >
      {children}
    </div>
  );
};

export default OVerlay;
