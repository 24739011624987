export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const cleanNumber = (number) => {
  if (String(number).includes(",")) {
    return String(number).split(",").join("");
  }
  return number;
};
export const formatCurrency = (num) => {
  if (!num) {
    return 0;
  }
  const cleanNum = cleanNumber(num.toString());
  const numb = Number(cleanNum);
  return String(numb.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const nairaSymbol = "₦";

export const truncateWords = (word = "", length) => {
  if (word.length <= length) {
    return word;
  }

  const truncateWord = `${word.substring(0, length)}`;

  return truncateWord.concat("...");
};

export const formatDate = (date) => {
  if (!date) return "";

  const theDate = new Date(date);
  const month = MONTHS[theDate.getMonth()];
  const year = theDate.getFullYear();
  const dateVal = theDate.getDate();
  if (!month || !year || !dateVal) return "";
  return `${month || ""} ${dateVal || ""}, ${year || ""}`;
};

// Function to add ordinal suffix
export function getOrdinal(day) {
  if (day > 3 && day < 21) return "th"; // Handle 11-13 as exceptions
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
