import React from "react";
import OVerlay from "../overlay";

const Modal = ({ children, showModal, showModalHandler, title, className }) => {
  return (
    <OVerlay visible={showModal}>
      {!showModal ? null : (
        <div
          className={`bg-white z-[9999] mx-2   shadow-md rounded-sm flex flex-col h-fit w-fit min-w-[80%] lg:min-w-[40%] xxxl:min-w-[30%] ${
            className ? className : ""
          } overflow-auto `}
        >
          <div className="flex justify-between mb-8 border-b border-text/3 p-4 ">
            <div>
              {title && (
                <h1 className=" font-medium text-text/80 font-body text-[18px]">
                  {title}
                </h1>
              )}
            </div>

            {/* {showModalHandler && ( */}
            <button
              className="rounded-full self-end w-[30px] h-[30px] bg-primary flex justify-center items-center"
              onClick={showModalHandler}
            >
              <span className="font-normal text-[12px] z-[999] font-main text-text/50">
                X
              </span>
            </button>
            {/* )} */}
          </div>

          <div className="px-4">{children}</div>
        </div>
      )}
    </OVerlay>
  );
};

export default Modal;
