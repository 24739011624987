import ReactPaginate from "react-paginate";
import propTypes from "prop-types";
import "./pagination.scss";

const Pagination = ({
  total = 0,
  limit = 20,
  handlePageClick,
  currentPage = 1,
}) => {
  const pageCount = Math.ceil(Number(total) / Number(limit));

  const handleClick = (page) => {
    handlePageClick(page.selected + 1);
  };
  return (
    <>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={currentPage}
        onPageChange={handleClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </>
  );
};

export default Pagination;
Pagination.propTypes = {
  pageCount: propTypes.any,
  handlePageClick: propTypes.func,
  currentPage: propTypes.any,
};
