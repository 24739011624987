export const UserType = {
  Admin: "Admin",
  Contestant: "Contestant",
  Voter: "Voter",
};

export const ContestType = {
  Paid: "Paid",
  Free: "Free",
};

export const VideoType = {
  Upload: "Upload",
  Url: "Url",
};

export const ContestantType = {
  Individual: "Individual",
  Group: "Group",
};

export const MultiChoice = {
  Yes: "Yes",
  No: "No",
  NotApplicable: "Not Applicable",
};

export const ContestantStatus = {
  Applied: "Pending",
  Cancelled: "Cancelled",
  Shortlisted: "Shortlisted",
  Disqualified: "Disqualified",
};

export const PaymentStatus = {
  Paid: "Paid",
  Pending: "Pending",
};

export const ContestPhaseType = {
  Physical: "Physical",
  Virtual: "Virtual",
};

export const BroadcastChannel = {
  SMS: "SMS",
  Email: "Email",
  Dashboard: "Dashboard",
};

export const BroadcastStatus = {
  Published: "Published",
  Draft: "Draft",
  Archive: "Archive",
};

export const UserStatus = {
  Active: "Active",
  Blocked: "Blocked",
  Inactive: "Inactive",
};
