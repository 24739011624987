import ContestsCardLink from "components/cards/ContestsCardLink";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import { useNavigate } from "react-router-dom";
import "./allContests.scss";
import useContests from "hooks/useContests";
import Jumbotron from "components/jumbotron/index";
import Skeleton from "react-loading-skeleton";
import Pagination from "components/pagination/Pagination";

const AllContests = () => {
  const navigate = useNavigate();

  const { data, isLoading, onQueryChange } = useContests();

  const handleCardLink = (contest) => {
    localStorage.setItem("contestTab", 0);
    navigate(
      `${ContentMgtURL.CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}/contests/${contest?._id}/details`,
      { state: { contest: contest?.title } }
    );
  };

  const handlePaginate = (page) => {
    onQueryChange("page", page);
  };
  return (
    <>
      <div className="contests-cardLink-container">
        {isLoading ? (
          <Skeleton height={300} width="48%" />
        ) : (
          <>
            {data?.data?.total < 1 ? (
              <Jumbotron message="No Contests" />
            ) : (
              <>
                {data?.data?.data?.map((contest) => (
                  <ContestsCardLink
                    key={contest?._id}
                    contest={contest}
                    clickHandler={handleCardLink}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
      <Pagination
        handlePageClick={handlePaginate}
        currentPage={data?.data.page}
        limit={data?.data?.limit}
        total={data?.data?.total}
      />
    </>
  );
};
export default AllContests;
