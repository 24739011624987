import appAxios from "config/axios";

export const handleGetRequest = async (payload) => {
  try {
    const { data } = await appAxios.get(payload);
    return data;
  } catch (err) {
    throw err;
  }
};

export const handlePostRequest = async (path, payload) => {
  const { data } = await appAxios.post(`${path}`, payload);
  return data;
};
export const handlePuttRequest = async (path, payload) => {
  try {
    const { data } = await appAxios.put(`${path}`, payload);
    return data;
  } catch (err) {
    throw err;
  }
};
export const handleDeleteRequest = async (payload) => {
  try {
    const { data } = await appAxios.delete(`${payload}`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const handlePatchRequest = async (path, payload) => {
  try {
    const { data } = await appAxios.patch(`${path}`, payload);
    return data;
  } catch (err) {
    throw err;
  }
};
