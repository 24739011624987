import {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const getUserDetails = async (id) => {
  return await handleGetRequest(`/auth/users/${id}/profile`);
};

export const deleteUser = async (id) => {
  return await handleDeleteRequest(`/auth/users/${id}`);
};

export const changeUserStatus = async (id, status) => {
  return await handlePuttRequest(`/auth/users/${id}/status`, { status });
};

export const getUserActivities = async (id, query) => {
  return await handleGetRequest(
    `/user-activities/users/${id}?page=${query.page}&limit=${query.limit}`
  );
};

export const updateUserProfile = async (data) => {
  return await handlePuttRequest(`/auth/users/profile`, data);
};

export const changePassword = async (data) => {
  return await handlePostRequest(`/auth/change-password`, data);
};
export const resetPassword = async (data) => {
  return await handlePostRequest(`/auth/reset-password`, data);
};

export const updateKyc = async (data) => {
  return await handlePatchRequest(`/auth/users/update-kyc`, data);
};

export const updateNotificationView = async () => {
  return await handlePatchRequest(`/auth/users/update-notification-view`, {});
};

export const sendMessage = async (data) => {
  return await handlePostRequest(`/general/send-message`, data);
};

export const getDashboardStats = async () => {
  return await handleGetRequest(`/auth/users/dashboard-stats`);
};

export const subscribeUser = async (data) => {
  return await handlePostRequest(`/general/user-subscription`, data);
};
