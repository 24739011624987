import React from "react";
import propTypes from "prop-types";
import { FaVideoSlash } from "react-icons/fa";

const ViewAllContestantsCard = ({
  video,
  videoUrl,
  title,
  paragraph,
  handleClick,
  className,
}) => {
  //     const handleClick = () => {
  //     clickHandler(id);
  //   };

  return (
    <>
      <div className="card__contestant__view shadow-md">
        <div className={className}>
          {!video ? (
            <div className="contestant-video-empty">
              <FaVideoSlash />
              No Current Submission{" "}
            </div>
          ) : (
            <>
              {video ? (
                <video
                  onClick={handleClick}
                  className="contestant-video"
                  // controls="controls"
                  preload="metadata"
                >
                  <source src={video?.url} type="video/mp4" />
                </video>
              ) : (
                <a href={videoUrl} target="_blank" rel="noreferrer">
                  Watch Video
                </a>
              )}
            </>
          )}

          <h3 className="title">{title}</h3>
          <p>{paragraph}</p>
        </div>
      </div>
    </>
  );
};

ViewAllContestantsCard.propTypes = {
  img: propTypes.string,
  title: propTypes.string,
  paragraph: propTypes.string,
  className: propTypes.string,
  handleClick: propTypes.func,
};
export default ViewAllContestantsCard;
