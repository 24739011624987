import Logout from "components/button/logout-button/Logout";
import RoundUserAvatar from "components/round-user-avatar/RoundUserAvatar";
import React from "react";
import { MdArrowForward } from "react-icons/md";
import { VOTER_PROFILE_INDEX_ROUTE } from "constants/urls/Voter";

import { Link } from "react-router-dom";
import "./avatar-dropdown.scss";

const AvatarDropdown = ({ imgSrc, showDropdown, name, userProfileUrl }) => {
  return (
    <div
      className={`${
        showDropdown
          ? "avatar-dropdown visibile card"
          : "avatar-dropdown invisible card"
      }`}
    >
      <div className="avatar-dropdown__content card__content">
        <div className="view-avatar__details">
          <Link
            // onClick={handleOpenCloseProfile}
            to={userProfileUrl ? userProfileUrl : VOTER_PROFILE_INDEX_ROUTE}
            className="view-details__link"
          >
            <div className="avatar__holder">
              <RoundUserAvatar imgSrc={imgSrc} />
            </div>
            <div className="avatar__data">
              <span className="avatar__data-name">{name}</span>
              {/* <span className="avatar__data-email">{email}</span> */}
            </div>
            <MdArrowForward />
          </Link>
        </div>
        <div
          // role="button"
          // onClick={handleLogout}
          className="logout-user__cta"
        >
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default AvatarDropdown;
