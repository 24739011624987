import * as ContentMgtUrl from "constants/urls/ContentManagement";

import { dashboard, love, message, todo, window } from "assets/index";

const contestantSideNavigationLinks = [
  {
    name: "dashboard",
    url: ContentMgtUrl.CONTESTANT_DASHBOARD_ROUTE,
    icon: dashboard,
  },
  {
    name: "contests",
    url: ContentMgtUrl.CONTESTANT_VIEW_ALL_CONTEST_ROUTE,
    icon: window,
  },
  {
    name: "prizes",
    url: ContentMgtUrl.CONTESTANT_VIEW_ALL_CONTEST_PRIZE_ROUTE,
    icon: love,
  },
  {
    name: "results",
    url: ContentMgtUrl.CONTESTANT_VIEW_PHASE_RESULTS_ROUTE,
    icon: message,
  },
  {
    name: "videos",
    url: ContentMgtUrl.CONTESTANT_VIEW_ALL_CONTEST_VIDEO,
    icon: todo,
  },
];

export default contestantSideNavigationLinks;
