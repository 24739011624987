import { Navigate, Outlet, redirect } from "react-router-dom";
import { getAccessToken } from "utils/storage";
import { jwtDecode } from "jwt-decode";
import { UserType } from "constants/enums";
import { LOGIN_ROUTE } from "constants/urls/Landing";

const VoterPrivateRoutes = () => {
  let accessToken = getAccessToken();

  // if there's no accestoken
  if (!accessToken) redirect(LOGIN_ROUTE);

  const decoded = jwtDecode(accessToken);

  // if it's not a valid token
  if (!decoded) redirect(LOGIN_ROUTE);

  // If user is not a contewstant
  if (decoded.type !== UserType.Voter) redirect(LOGIN_ROUTE);

  return decoded && decoded?.type === UserType.Voter ? (
    <Outlet />
  ) : (
    <Navigate to={LOGIN_ROUTE} />
  );
};

export default VoterPrivateRoutes;
