import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  VOTER_INDEX_ROUTE,
  VOTER_PROFILE_INDEX_ROUTE,
} from "constants/urls/Voter";
import { CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE } from "constants/urls/ContentManagement";
import BrandLoader from "components/brand-loader/BrandLoader";
import LandingRoutes from "./landing/LandingRoutes";
import ContestantRoutes from "./content-management/ContestantRoutes";
import ViewAllContestant from "pages/voter/view-all-contestant/ViewAllContestant";
import VoterProfile from "pages/voter-profile/VoterProfile";
import ContestantProtectedRoute from "./protectedRoute/ContestantProtectedRoute";
import VoterPrivateRoutes from "./protectedRoute/VoterProtectedRoute";

const VoterLayout = lazy(() => import("layouts/voter-landing/VoterLanding"));
const VoteContestant = lazy(() => import("pages/voter/vote-contestant"));
const VotePhases = lazy(() => import("pages/voter/phases/ContestsPhases"));
const ContestantContentLayout = lazy(() =>
  import("layouts/content-management/ContestantDashBoardLayout")
);

const PageNotFound = lazy(() => import("pages/page-not-found/PageNotFound"));

const GlobalRoutes = () => {
  return (
    <>
      <Suspense fallback={<BrandLoader />}>
        <Routes>
          <Route path={"/*"} element={<LandingRoutes />} />

          {/* PROTECTED VOTER ROUTE */}
          <Route element={<VoterPrivateRoutes />}>
            <Route path={VOTER_INDEX_ROUTE} element={<VoterLayout />} />
            <Route
              path={VOTER_PROFILE_INDEX_ROUTE}
              element={<VoterProfile />}
            />
            <Route
              path={`${VOTER_INDEX_ROUTE}/contestant/:id`}
              element={<VoteContestant />}
            />
            <Route
              path={`${VOTER_INDEX_ROUTE}/phases/:id/vote-contestant`}
              element={<ViewAllContestant />}
            />
            <Route
              path={`${VOTER_INDEX_ROUTE}/contests/:id/phases`}
              element={<VotePhases />}
            />
          </Route>

          {/* PROTECTED CONTESTANR ROUTE */}
          <Route element={<ContestantProtectedRoute />}>
            <Route
              path={CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}
              element={<ContestantContentLayout />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={`${CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}/dashboard`}
                  />
                }
              />

              <Route
                path={`${CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}/*`}
                element={<ContestantRoutes />}
              />
            </Route>
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default GlobalRoutes;
