import propTypes from "prop-types";
import "./headshotUpload.css";
import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { uploadFile } from "services/files.service";
import { DefaultUser } from "assets/index";
import Image from "components/image/index";
// import Image from "../IMAGE/Image";

const HeadshotUpload = ({
  label = "Add Headshot",
  onChange,
  name,
  src,
  errorLabel,
  allowedFiles,
  size = "10485760",
  fallbackSrc = DefaultUser,
  value,
}) => {
  const [fileName, setFileName] = useState("");
  const [validationMessage, setValidationMessage] = useState(errorLabel);
  // const [srcState, setSrcState] = useState(src);
  // const [errored, setErrored] = useState(false);
  const [imgSrc, setImgSrc] = useState(value);
  const fileInput = useRef();

  useEffect(() => {
    if (!imgSrc) setImgSrc(src);
  }, [src, imgSrc]);

  const handleFileBrowse = () => {
    fileInput.current.click();
  };

  const { mutate: uploadFileMutate } = useMutation(
    async (formData) => await uploadFile(formData),
    {
      onSuccess(data) {
        const uploadedFile = {
          name: data.data?.fileName,
          size: data?.data?.size,
          url: data.data.url,
          id: data.data.id,
          type: data?.data?.type,
        };
        onChange(name, uploadedFile);
        toast.success(data.message);
      },

      onError(error) {
        console.log(error, "error");
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleSetFileName = (name) => {
    setFileName(name);
  };
  const handleSetFilePreview = (file) => {
    setImgSrc(URL.createObjectURL(file));
  };
  // max 10mb
  const isValidFileSize = (fileSize) => {
    if (fileSize > Number(size)) {
      setValidationMessage("File size must be 10mb max");
      return false;
    }
    setValidationMessage("");
    return true;
  };

  // allowed file type: ["pdf", "txt", "docx", "xlsx"]
  const isValidFileType = (file) => {
    const allowedExtensions = allowedFiles
      ? [allowedFiles]
      : ["png", "jpg", "jpeg", "ppt", "bmp"];
    const fileExtension = file.type.split("/")[1];

    if (!allowedExtensions.includes(fileExtension)) {
      setValidationMessage(
        `required file type is any of: ${allowedExtensions.toLocaleString()}`
      );

      return false;
    }
    setValidationMessage("");
    return true;
  };

  const handleFileSelect = async (e) => {
    const files = e.target.files;
    const validFiles = [];

    for (let file of files) {
      if (isValidFileSize(file.size) && isValidFileType(file)) {
        validFiles.push(file);
        handleSetFileName(file.name);
        handleSetFilePreview(file);
      }
    }
    if (validFiles.length) {
      const formData = new FormData();
      formData.append("file", validFiles[0]);
      uploadFileMutate(formData);
    }
  };
  // const handleOnError = () => {
  //   if (!errored) {
  //     setImgSrc(fallbackSrc);
  //     setErrored(true);
  //   }
  // };
  return (
    <div className="upload-headshot">
      <div>
        <div className="photo-ctn" onClick={handleFileBrowse}>
          <div className="photo">
            <Image src={imgSrc} fallbackSrc={fallbackSrc} alt="" />
            {/* <img src={imgSrc} alt="" onError={handleOnError} /> */}
          </div>
          <div className="headshot-btn-ctn">
            <div type="button" className="browse-btn">
              <span>{label}</span>
            </div>
          </div>
        </div>
      </div>
      <span> {fileName} </span>
      <div className="form-input-ctn">
        <input
          type="file"
          accept="image/*"
          className="file-input"
          onChange={handleFileSelect}
          ref={fileInput}
        />
        {errorLabel ? (
          <span className="error-label"> {errorLabel} </span>
        ) : null}
      </div>

      <label
        className={`label error-msg ${
          validationMessage && "error-msg-visible"
        }`}
      >
        {validationMessage}
      </label>
    </div>
  );
};

export default HeadshotUpload;
HeadshotUpload.propTypes = {
  label: propTypes.string,
  uploadHandler: propTypes.func,
  name: propTypes.string,
  errorLabel: propTypes.string,
  src: propTypes.string,
  allowedFiles: propTypes.string,
  size: propTypes.any,
  fallbackSrc: propTypes.any,
};
