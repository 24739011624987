import { brandLogo } from "assets/index";
import contestantSideNavigationLinks from "components/side-navigations/navigation-data/contestant";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE } from "constants/urls/ContentManagement";

export default function MobileDashboardSidebar({ onClose }) {
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const navigation = useNavigate();

  const isActiveMenu = (name) => {
    return activeMenu === name;
  };

  const handleSelction = (menu) => {
    setActiveMenu(menu.name);

    onClose();
    navigation(`${CONTESTANT_CONTENTMANGEMENT_INDEX_ROUTE}/${menu.url}`, {
      replace: true,
    });
  };

  return (
    <aside className=" bg-[#090702] flex flex-col min-h-[700px]">
      <div className="flex justify-between items-center h-[78px] px-4 border-b border-[#0000000D]">
        <img
          src={brandLogo}
          alt="logo"
          className="w-[85px] h-[53px] object-contain"
        />
        <button className="text-background font-heading" onClick={onClose}>
          <span>X</span>
        </button>
      </div>
      <div className="flex flex-col px-7 gap-3 mt-6">
        <nav className=" gap-[30px] flex flex-col mt-3">
          {contestantSideNavigationLinks.map((route) => (
            <button
              onClick={() => handleSelction(route)}
              key={route.name}
              className={`text-background text-base p-4 font-body hover:text-background font-medium capitalize leading-[19.2px]  ${
                isActiveMenu(route.name)
                  ? "  border-l-[4px]  rounded-r-md  border-primary"
                  : ""
              }`}
            >
              <div className="flex  gap-4">
                <img src={route.icon} className="w-[20px] h-[20px]" alt="" />
                <span>{route.name}</span>
              </div>
            </button>
          ))}
        </nav>
      </div>
    </aside>
  );
}
